import { FacilityContext } from '@kargo/context/facility';
import { useContext } from 'react';

export const useFacility = () => {
  const { facility, availableFacilities, setFacility } =
    useContext(FacilityContext);

  if (!facility || !availableFacilities) {
    throw new Error('Facilities must be initialized to use FacilityContext');
  }

  return { facility, availableFacilities, setFacility };
};
