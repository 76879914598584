import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

const KargoLogo = (props: SvgIconProps): JSX.Element => {
  return (
    <SvgIcon
      fill='none'
      width='100'
      height='140'
      viewBox='0 0 100 140'
      {...props}
    >
      <path
        d='M50.234 -0.000324754L99.6455 139.999L50.234 114.21L0.822418 139.999L50.234 -0.000324754Z'
        fill='#FF4800'
      />
    </SvgIcon>
  );
};

export { KargoLogo };
