import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { VideocamOutlined } from '@mui/icons-material';
import { CameraLocation } from 'generated/graphql';
import { Fragment, useCallback } from 'react';
import { KargoTooltip } from '../tooltip';

const StyledKargoCameraLocationContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  gap: 4px;
`;

type StyledStyledKargoCameraLocationItemWrapperProps = {
  isSelected: boolean;
};

const StyledStyledKargoCameraLocationItemWrapper = styled.span<StyledStyledKargoCameraLocationItemWrapperProps>`
  background-color: ${(p) => p.theme.colors.white};
  border: 2px solid
    ${(p) => (p.isSelected ? p.theme.colors.black : 'transparent')};
`;

type StyledKargoCameraLocationItemProps = {
  isSelected: boolean;
};

const StyledKargoCameraLocationItem = styled.button<StyledKargoCameraLocationItemProps>`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 0.75rem;
  height: 40px;
  padding: 10px 6px;
  background-color: ${(p) => p.theme.colors.white};
  opacity: 1;
  transition: opacity 0.2s ease-in-out;

  :focus-visible {
    border: 2px solid ${(p) => p.theme.colors.gray400};
  }

  :hover {
    ${(p) =>
      !p.isSelected &&
      css`
        opacity: 0.7;
      `}
  }

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

type StyledKargoCameraLocationIconProps = {
  isSelected: boolean;
};

const StyledKargoCameraLocationIcon = styled(VideocamOutlined, {
  shouldForwardProp: (prop) => prop !== 'isSelected',
})<StyledKargoCameraLocationIconProps>`
  color: ${(p) =>
    p.isSelected ? p.theme.colors.black : p.theme.colors.gray400};
  margin-right: 8px;
`;

export const CameraLocationDisplayMap: Record<CameraLocation, string> = {
  [CameraLocation.TOP_LEFT]: 'Top Left',
  [CameraLocation.TOP_RIGHT]: 'Top Right',
  [CameraLocation.BOTTOM_LEFT]: 'Bottom Left',
  [CameraLocation.BOTTOM_RIGHT]: 'Bottom Right',
  [CameraLocation.FORKLIFT]: 'Forklift',
  [CameraLocation.FORKLIFT_PICKUP]: 'Forklift Pickup',
  [CameraLocation.FORKLIFT_DROP_OFF]: 'Forklift Dropoff',
};

// List order for rendering camera location buttons in proper order
export const orderedCameraLocations: CameraLocation[] = [
  CameraLocation.TOP_LEFT,
  CameraLocation.TOP_RIGHT,
  CameraLocation.BOTTOM_LEFT,
  CameraLocation.BOTTOM_RIGHT,
  CameraLocation.FORKLIFT,
  CameraLocation.FORKLIFT_PICKUP,
  CameraLocation.FORKLIFT_DROP_OFF,
];

const forkliftCameraLocations: CameraLocation[] = [
  CameraLocation.FORKLIFT_PICKUP,
  CameraLocation.FORKLIFT_DROP_OFF,
];

// Camera locations that are always rendered (disabled if options does not include)
const alwaysShownCameraLocations: CameraLocation[] = [
  CameraLocation.TOP_LEFT,
  CameraLocation.TOP_RIGHT,
  CameraLocation.BOTTOM_LEFT,
  CameraLocation.BOTTOM_RIGHT,
];

type Props = {
  selectedCameraLocation: CameraLocation | null;
  isForkliftOnly?: boolean;
  cameraLocationOptions: Partial<CameraLocation>[];
  onCameraLocationChange: (cameraLocation: CameraLocation) => void;
};

const KargoCameraLocationSelector = ({
  selectedCameraLocation,
  isForkliftOnly,
  cameraLocationOptions,
  onCameraLocationChange,
}: Props): JSX.Element => {
  if (
    selectedCameraLocation &&
    !cameraLocationOptions.includes(selectedCameraLocation)
  ) {
    throw new Error(
      'Invalid selectedCameraLocation prop. selectedCameraLocation not in list of cameraLocationOptions',
    );
  }

  const onCameraLocationClick = useCallback(
    (updatedCameraLocation: CameraLocation) => {
      onCameraLocationChange(updatedCameraLocation);
    },
    [onCameraLocationChange],
  );

  const cameraLocations = isForkliftOnly
    ? forkliftCameraLocations
    : orderedCameraLocations;

  return (
    <StyledKargoCameraLocationContainer>
      {cameraLocations.map((cameraLocation) => {
        const isSelected = cameraLocation === selectedCameraLocation;
        const isCameraLocationInOptions =
          cameraLocationOptions.includes(cameraLocation);
        const shouldShowDisabled =
          alwaysShownCameraLocations.includes(cameraLocation);

        return (
          <Fragment key={cameraLocation}>
            {(isCameraLocationInOptions || shouldShowDisabled) && (
              <KargoTooltip
                enterDelay={700}
                title={
                  shouldShowDisabled && !isCameraLocationInOptions
                    ? 'No video available for camera location'
                    : ''
                }
              >
                <StyledStyledKargoCameraLocationItemWrapper
                  isSelected={isSelected}
                >
                  <StyledKargoCameraLocationItem
                    isSelected={isSelected}
                    disabled={!isCameraLocationInOptions}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      onCameraLocationClick(cameraLocation);
                    }}
                  >
                    <StyledKargoCameraLocationIcon isSelected={isSelected} />

                    {CameraLocationDisplayMap[cameraLocation]}
                  </StyledKargoCameraLocationItem>
                </StyledStyledKargoCameraLocationItemWrapper>
              </KargoTooltip>
            )}
          </Fragment>
        );
      })}
    </StyledKargoCameraLocationContainer>
  );
};

export { KargoCameraLocationSelector };
