import styled from '@emotion/styled';
import type { TooltipProps } from '@mui/material/Tooltip';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const KargoTooltip = styled((props: TooltipProps) => (
  <Tooltip
    enterDelay={300}
    enterNextDelay={300}
    {...props}
    classes={{
      popper: props.className,
    }}
  />
))`
  & .${tooltipClasses.tooltip} {
    background-color: ${(p) => p.theme.colors.gray500};
    color: ${(p) => p.theme.colors.white};
  }
`;

export { KargoTooltip };
